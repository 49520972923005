import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import RichText from '~components/RichText'
import { 
	Text,
	TextEl
} from '~styles/global'

const TextModule = ({ className, text }) => {
	return (
		<Wrap className={className}>
			<Text>
				<TextEl className="h3">
					<RichText content={text}/>
				</TextEl>
			</Text>
		</Wrap>
	)
}

const Wrap = styled.div`
	
`

TextModule.propTypes = {
	className: PropTypes.string
}

export default TextModule