import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import * as Flickity from 'react-flickity-component'
import styled from '@emotion/styled'
import '~styles/flickity.css'
import Section from './Section'
import { mobile } from '~styles/global'
import Image from '~components/Image'

const MarqueeSlider = ({className, images}) => {

	let requestId
	let n = 0
	let flkty = null

	const play = () => {
		// Set the decrement of position x
		window.cancelAnimationFrame(requestId)
		if (flkty) {
			flkty.x = flkty.x - 0.5
			// Settle position into the slider
			flkty.settle(flkty.x)
			// Set the requestId to the local variable
			requestId = window.requestAnimationFrame(play)
		}
	}

	const easeOut = () => {
		if(requestId) {
			// Cancel the animation
			window.cancelAnimationFrame(requestId)
			n++
			flkty.x = flkty.x - (1 - (n / 50)) * 0.5
			flkty.settle(flkty.x)
			// Reset the requestId for the next animation.
			requestId = window.requestAnimationFrame(easeOut)
			if(n > 50){
				pause()
			}
		}
	}

	const pause = () => {
		if(requestId) {
			// Cancel the animation
			window.cancelAnimationFrame(requestId)
			// Reset the requestId for the next animation.
			requestId = undefined
			n = 0
		}
	}
  
	useEffect(()=> {
		play()
	}, [])

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: true,
		cellAlign: 'left',
		freeScroll: true,
		contain: true,
		// cellAlign: 'center',
	}

	return(
		<Wrap className={className}>
			<div 
				onMouseLeave={()=> play()}
				onMouseEnter={()=> easeOut()}
				// onMouseDown={() => setInteracted(true)}
				css={css`
					grid-column: span 12;
					overflow: hidden;
				`}
			>
				<Flickity
					flickityRef={c => flkty = c}
					className={'carousel'} // default ''
					elementType={'div'} // default 'div'
					options={flickityOptions} // takes flickity options {}
					disableImagesLoaded={false} // default false
					// static // default false
					css={sliderCss}
				>
					{[...Array(5)].map(i => (
						<React.Fragment key={i}>
							{images?.map(image => (
								<Slide key={image._key} css={css`
									width: ${400 * image?.asset?.metadata?.dimensions?.aspectRatio}px;
									${mobile}{
										width: ${200 * image?.asset?.metadata?.dimensions?.aspectRatio}px;
									}
								`}>
									<Image  image={image} />
								</Slide>
							))}
						</React.Fragment>
					))}
					
				</Flickity>
			</div>
		</Wrap>
	)
}

const Wrap = styled(Section)`
 margin-bottom: var(--xxxxl);
`
const sliderCss = css`
  grid-column: span 12;
  margin-right: -40px;
  margin-left: -40px;
  ${mobile}{
    margin-right: -15px;
    margin-left: -15px;
  }
`

const Slide = styled.div`

`

MarqueeSlider.propTypes = {
	className: PropTypes.string,
	images: PropTypes.object,
}

export default MarqueeSlider
