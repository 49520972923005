import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import Section from '~components/Section'

const ImageModule = ({ className, image, alignment }) => {
	return (
		<Wrap className={className}>
			<StyledImage image={image} alignment={alignment} />
		</Wrap>
	)
}

const imageAlignment = props => {
	if(props.alignment === 'left'){
		return css`
			grid-column: 1 / 9;
			${mobile}{
				grid-column: span 12;
			}
		`
	} else if(props.alignment === 'right'){
		return css`
			grid-column: 5 / 13;
			${mobile}{
				grid-column: span 12;
			}
		`
	} else if(props.alignment === 'fullWidth'){
		return css`
			grid-column: span 12;
			${mobile}{
				grid-column: span 12;
			}
		`
	} else {
		// center align
		return css`
			grid-column: 3 / 11;
			${mobile}{
				grid-column: span 12;
			}
		`
	}
}

const Wrap = styled(Section)`
	margin-bottom: var(--xxxxl);
`

const StyledImage = styled(Image)`
	${imageAlignment}
`

ImageModule.propTypes = {
	className: PropTypes.string,
	image: PropTypes.object,
	alignment: PropTypes.string,
}

export default ImageModule