import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css, Global } from '@emotion/react'
import { mobile, tablet, BlurFilter } from '~styles/global'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import RenderModules from '~components/RenderModules'
import VideoPlayer from '~components/VideoPlayer'
import Logo from '~components/Logo'
import { useSiteState } from '~context/siteContext'
import Image from '~components/Image'
import { 
	Text,
	TextEl,
	Credits 
} from '~styles/global'
import useWindow from '~utils/useWindow'

const Page = ({ data }) => {
	const { title, content } = data.page
	const [siteState, setSiteState] = useSiteState()
	const [atBottom, setAtBottom] = useState(false)
	const [playing, setPlaying] = useState(false)

	// useEffect(() => {
	// 	setSiteState({...siteState, projectTitle: [title, content?.subtitle]})
	// }, [])

	const window = useWindow()

	useEffect(() => {
		setTimeout(() => {
			setSiteState(prevState => ({
				...prevState, 
				projectTitleSticky: true,
				projectTitle: [title, content?.subtitle]
			}))
		}, 100)
	}, [])

	useEffect(() => {
		if(window.scrollY > (document.body.clientHeight - (window.innerHeight * 2))){
			setAtBottom(true)
		} else {
			setAtBottom(false)
		}
	}, [window?.scrollY])

	const headerAspectRatio = content?.headerVideo?.oEmbedData?.width / content?.headerVideo?.oEmbedData?.height

	return(
		<>
			<Global styles={css`
					:root{
						--olive: #675E3C;
						--olivetrans: rgba(103, 94, 60, 0);
						/* --olive: #3B3729;
						--olivetrans: rgba(59, 55, 41, 0); */
					}
					.project-title h2{
						opacity: ${atBottom ? '0' : 1};
					}
				`} />
			<Seo 
				title={title}
				metaTitle={content?.seo?.metaTitle}
				description={content?.seo?.metaDescription}
				image={content?.seo?.socialImage}
			/>
			<Logo noSpace={true} />
			<BlurFilter />
			<HeaderVideoSection>
				{content?.headerVideo ? 
					<HeaderVideo 
						videoId={content?.headerVideo?.oEmbedData?.video_id} 
						aspectRatio={headerAspectRatio} 
						thumb={content?.headerVideoThumbnail}
						onPlay={() => setPlaying(true)}
						onPause={() => setPlaying(false)}
						css={css`
							grid-column: ${headerAspectRatio > 1 ? '3 / 11' : '5 / 9'};
							max-width: ${headerAspectRatio > 1 ? 'none' : '25vw'};
							margin: 0 auto;
							z-index: ${playing ? 400 : 0};
						`}
					/> :
					<HeaderImage image={content?.headerVideoThumbnail} css={css`
						grid-column: ${content?.headerVideoThumbnail?.asset?.metadata?.dimensions?.aspectRatio > 1 ? '3 / 11' : '4 / 10'};
					`}/>
				}
				
			</HeaderVideoSection>
			<Text>
				<Title>
					{title}
					{content.subtitle && 
						<>
							<br/>
							{content.subtitle}
						</>
					}
				</Title>
				<TextEl className="h3">
					<RichText content={content?.introduction}/>
				</TextEl>
				<Credits className="h4">
					<RichText content={content?.creditsText}/>
					{/* {content?.credits?.map(credit => (
						<p key={credit.key}>{credit?.role}: {credit.name}</p>
					))} */}
				</Credits>
			</Text>
			<RenderModules modules={content?.modules} />
			<Space />
			<DataViewer data={content} name="content"/>
		</>
	)
}

Page.propTypes = {
	data: PropTypes.object,
}

const Title = styled.h2`
	margin-bottom: var(--l);
	display: none;
	grid-column: span 12;
	${tablet}{
		display: block;
	}
`

const HeaderVideoSection = styled(Section)`
	min-height: 100vh;
	align-items: center;
	display: flex;
	${mobile}{
		min-height: 0;
	}
	div{
		width: 100%;
	}
`

const HeaderVideo = styled(VideoPlayer)`
	margin: var(--xxl) 0;
	position: relative;
	${mobile}{
		grid-column: span 12;
		margin: calc(26vw + 90px) 0 70px;
		max-width: none !important;
	}
`

const HeaderImage = styled(Image)`
	margin: var(--xxl) 0;
	position: relative;
	${mobile}{
		grid-column: span 12;
		margin: calc(26vw + 90px) 0 70px;
	}
`

const Space = styled.div`
	height: 100vh;
	width: 100%;
`

export const query = graphql`
  query ProjectQuery($slug: String) {
    page: sanityProject(slug: {current: {eq: $slug}}) {
      title
      content {
				subtitle
				headerVideo {
					...vimeoVideo
				}
				headerVideoThumbnail {
					...imageWithAlt
					asset {
						metadata {
							dimensions {
								aspectRatio
							}
						}
					}
				}
				introduction: _rawIntroduction
				creditsText: _rawCreditsText(resolveReferences: {maxDepth: 5})
				modules {
					... on SanityImageCarousel {
						_key
						_type
						images{
							_key
							...imageWithAlt
							asset {
								metadata {
									dimensions {
										aspectRatio
									}
								}
							}
						}
					}
					... on SanityImageModule {
						_key
						_type
						image{
							...imageWithAlt
						}
						alignment
					}
					... on SanityTextModule {
						_key
						_type
						text: _rawText(resolveReferences: {maxDepth: 5})
					}
					... on SanityVimeoEmbed {
						_key
						_type
						video{
							...vimeoVideo
						}
					}
				}
				seo{
					...seo
				}
      }
    }
  }
`

export default Page