import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/Section'
import ImageModule from '~components/ImageModule'
import TextModule from '~components/TextModule'
import MarqueeSlider from '~components/MarqueeSlider'
import VideoPlayer from '~components/VideoPlayer'

const RenderModules = ({ className, modules }) => {
	if(!modules) return null
	return (
		<div className={className}>
			{modules.map(module => <RenderModule key={module._key} module={module} />)}
		</div>
	)
}

const RenderModule = ({ module }) => {
	switch (module._type) {
	case 'imageModule':
		return <ImageModule image={module?.image} alignment={module?.alignment} />
	case 'textModule':
		return <TextModule text={module?.text} />
	case 'imageCarousel':
		return <MarqueeSlider images={module?.images} />
	case 'vimeoEmbed':
		return (
			<Section>
				<VideoPlayer videoId={module?.video?.oEmbedData?.video_id} aspectRatio={module?.video?.oEmbedData?.width / module?.video?.oEmbedData?.height} css={css`
					grid-column: 3 / 11;
					margin-bottom: var(--xxxxl);
					grid-column: ${(module?.video?.oEmbedData?.width / module?.video?.oEmbedData?.height) > 1 ? '3 / 11' : '5 / 9'};
					max-width: ${(module?.video?.oEmbedData?.width / module?.video?.oEmbedData?.height) > 1 ? 'none' : '25vw'};
					margin-left: auto;
					width: 100%;
					margin-right: auto;
					${mobile}{
						grid-column: span 12;
						max-width: none;
					}
				`}/>
			</Section>
		)
	default:
		return null
	}
}

RenderModules.propTypes = {
	className: PropTypes.string,
	modules: PropTypes.object,
}

RenderModule.propTypes = {
	module: PropTypes.object,
}

export default RenderModules