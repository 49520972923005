import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Player from '@vimeo/player'
import Image from '~components/Image'

const VideoPlayer = ({ className, videoId, aspectRatio, thumb, onPlay, onPause }) => {
	const [vimeoPlayer, setVimeoPlayer] = useState()
	const [interacted, setInteracted] = useState(false)
	const [vimeoLoaded, setVimeoLoaded] = useState()

	let options = {
		id: videoId,
		loop: false,
		byline: false,
		title: false,
		portrait: false,
		color: '847747',
		controls: true,
	}

	useEffect(() => {
		if(interacted){
			vimeoPlayer.play()
		}
	}, [interacted])

	// const handleEnd = () => {
	// 	vimeoPlayer.play()
	// }

	useEffect(() => {
		if(vimeoPlayer){
			// vimeoPlayer.on('ended', () => handleEnd())
			vimeoPlayer.on('loaded', () => setVimeoLoaded(true))
			vimeoPlayer.on('play', () => onPlay())
			vimeoPlayer.on('pause', () => onPause())
		}
	}, [vimeoPlayer])

	const videoContainer = useCallback(node => {
		if(node) {
			setVimeoPlayer(new Player(node, options))
		}
	}, [])

	return (
		<Wrap className={className}>
			<Video ref={videoContainer} css={css`
				opacity: ${vimeoLoaded || !thumb ? 1 : 0};
				padding-bottom: ${1 / aspectRatio * 100 + '%'};
			`}/>
			<Play onClick={() => setInteracted(true)}
				css={css`
						opacity: ${interacted ? '0' : '1'};
						pointer-events: ${interacted ? 'none' : 'all'};
					`}
			>
				<Image image={thumb} aspectRatio={aspectRatio} />
				<h3>Play</h3>
			</Play>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
	background: #595030;
`

const Video = styled.div`
	position: relative; 
	height: 0; 
	overflow: hidden; 
	max-width: 100%;
	width: 100%;
	transition: opacity 1s;
	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`

const Play = styled.button`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	z-index: 5;
	color: var(--white);
	transition: opacity 0.5s;
	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		div {
			margin-top: 0;
			height: 100%;
			img{
				object-fit: cover !important;
			}
		}
	}
	h3{
		text-align: center;
		transition: transform 0.35s;
		position: relative;
		z-index: 5;
	}
	&:hover{
		h3{
			transform: scale(1.05);
		}
	}
`

VideoPlayer.propTypes = {
	className: PropTypes.string,
	videoId: PropTypes.string,
	aspectRatio: PropTypes.number,
	thumb: PropTypes.object,
}

export default VideoPlayer